import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';

function NotFound() {
    return (
        <div>
            <Header />
            <h1>Oops...la page demandée ne semble pas exister</h1>
            <h3><Link to="/">Revenir à l'accueil</Link></h3>
        </div>
    )
} 

export default NotFound;