import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useReactMediaRecorder } from 'react-media-recorder-2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'
import { faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'

export default function Record({ setUserInput, recordingRef }) {
    const [isRecording, setIsRecording] = useState(false)
    const {
        startRecording,
        stopRecording,
        mediaBlobUrl,
        } = useReactMediaRecorder({
            video: false,
            audio: true,
            blobPropertyBag: {
                type: "audio/mp3"
            }
        })

    useEffect(() => {
        recordingRef.current = isRecording
        const fetchFile = async () => {
          if (!mediaBlobUrl) return
          fetchData()
        }

        const fetchData = async () => {
            const blobResponse = await axios.get(mediaBlobUrl, {responseType: 'blob'})
            const formData = new FormData()
            formData.append('file', blobResponse.data, 'audio.mp3')
            formData.append('model', 'whisper-1')
            formData.append('response_format', 'text')
            formData.append('language', 'fr')
            const config  = {
              headers: {
                "Content-Type": `multipart/form-data boundary=${formData._boundary}`,
                "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              },
            }
            await axios.post('https://api.openai.com/v1/audio/transcriptions', 
                formData, 
                config
            ).then((response) => {
                console.log(response)
                setUserInput(response.data)
            }).catch((error) => {
                console.log(error)
                setUserInput("Une erreur semble être survenue lors de l'enregistrement, nous nous excusons pour le dérangement. Merci de privilégier la rédaction à la diction.")
            })
            recordingRef.current = !recordingRef
          }
        fetchFile()
      }, [mediaBlobUrl, setUserInput, isRecording, recordingRef])
    
    const handleStream = () => {
        isRecording ? stopRecording() : startRecording()
        setIsRecording(!isRecording)
    }

    return (
        <div style={{display:'inline-block'}}>
            <button onClick={handleStream} className='iconButton'>
                <FontAwesomeIcon icon={isRecording ? faMicrophoneSlash : faMicrophone} /> Dicter
            </button>
        </div>
    )
}
