import React, { useState } from 'react'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { useNavigate } from "react-router-dom"
import './AuthForm.css' 
import { auth } from './firebase'
import Header from './Header'

const AuthForm = () => {
    const [email, setEmail] = useState('')
    const [inEmail, setInEmail] = useState('')
    const [password, setPassword] = useState('')
    const [username, setUsername] = useState('')
    const [inPassword, setInPassword] = useState('')
    const [activeForm, setActiveForm] = useState("connection")
    const [errorUser, setError] = useState(null)

    const handleToggleForm = (formName) => {
        setActiveForm(formName)
    }

    const navigate = useNavigate()
    const handleSignUp = async () => {
        try {
            await createUserWithEmailAndPassword(auth, email, password)

            updateProfile(auth.currentUser, {
                displayName: username
            })
            // .then(() => {
            //     var actionCodeSettings = {
            //         url: 'https://documed.page.link/SiJ8',
            //         handleCodeInApp: true,
            //         iOS: {
            //           bundleId: 'com.courrier.ios'
            //         },
            //         android: {
            //           packageName: 'com.courrier.android',
            //           installApp: true,
            //           minimumVersion: '12'
            //         },
            //       };
            //     sendSignInLinkToEmail(auth, email, actionCodeSettings)
            //         .then(() => {
            //             navigate('/')
            //         })
            //         .catch(error => console.log(error))
            //   }).catch((error) => {
            //     console.log(error)
            // }) 
        } catch (error) {
            setError(error.code)
        }
    }

    const handleSignIn = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, inEmail, inPassword)
            console.log(userCredential)
            if (userCredential.user) {
                console.log("redirect")
                navigate("/")
            }
        } catch (error) {
            console.error("Erreur lors de l'inscription", error)
            setError(error.code)
        }
    }

    return (
        <div>
            <Header />
            <div className="auth-form">
                <div className="toggle-buttons">
                    <button
                        className={activeForm === "connection" ? "active" : ""}
                        onClick={() => handleToggleForm("connection")}
                    >
                        Connexion
                    </button>
                    <button
                        className={activeForm === "registration" ? "active" : ""}
                        onClick={() => handleToggleForm("registration")}
                    >
                        Inscription
                    </button>
                </div>

                {errorUser && (
                    <div className='warning'>Une erreur est survenue - {errorUser}</div>
                )}

                {activeForm === "connection" && (
                    <div className="connection-form">
                        <h2>Connexion</h2>
                        <input type="email" placeholder="Adresse e-mail" value={inEmail} onChange={(e) => setInEmail(e.target.value)}/>
                        <input type="password" placeholder="Mot de passe" value={inPassword} onChange={(e) => setInPassword(e.target.value)}/>
                        <button type="submit" onClick={handleSignIn}>Se connecter</button>
                    </div>
                )}
            
                {activeForm === "registration" && (
                    <div className="registration-form">
                        <h2>Inscription</h2>
                        <input type="text" placeholder="Nom d'utilisateur" value={username} onChange={(e) => setUsername(e.target.value)} />
                        <input type="email" placeholder="Adresse e-mail" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <input type="password" placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        <button type="submit" onClick={handleSignUp}>S'inscrire</button>
                    </div>
                )}
                </div>
            </div>
    )
}


export default AuthForm
