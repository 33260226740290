import React, { useState, useRef } from 'react'
import OpenAI from "openai"
import './AuthForm.css'
import Record from './Record'
import BeatLoader from "react-spinners/BeatLoader"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

const openai = new OpenAI({ apiKey: 'sk-proj-WkVVes4MsVbXlrlt5VbWT3BlbkFJjfBwLCvxk9ytfbdaAzTK', dangerouslyAllowBrowser: true })

const Courrier = () => {
  const recordingRef = useRef(false);
  const [userInput, setUserInput] = useState('')
  const [activatedButton, setActivatedButton] = useState('hospitalisation')
  const [response, setResponse] = useState('')
  const [loading, setLoading] = useState(false)
  const [unformattedResponse, setUResponse] = useState('')
  const [isCopied, setCopied] = useState(false)

  const handleSendClick = async () => {
    try {
      setLoading(true)
      const systemMessageConsultation = "Tu es rédacteur de courriers médicaux destinés à des médecins concernant un patient. Si tu n'as pas d'informations sur l'identité du patient, indique simplement un ou le patient sinon indique monsieur ou madame. Tu prends les informations fournies et tu en fais un courrier démarrant par ”Cher confrère, ”. N'invente rien, base toi uniquement sur les informations qui te sont fournies. C'est un courrier à trou, il faut des emplacements [NOM DE FAMILLE] après monsieur ou madame, [Prenom NOM] et [Titre] pour l'identité de celui qui rédige le courrier. N'oublie pas que tu es dans un domaine médical, les notes peuvent être des abréviations comme X-X-X indiquant les prises dans la journée (matin, midi, soir)."
      const systemMessageHospitalisation =
        "Tu es rédacteur de courriers médicaux destinés à des médecins concernant un patient. Si tu n'as pas d'informations sur l'identité du patient, indique simplement un ou le patient sinon indique monsieur ou madame. Tu prends les informations fournies et tu en fais un courrier démarrant par ”Cher confrère, ”. C'est un courrier à trou, il faut des emplacements [NOM DE FAMILLE] après monsieur ou madame, [Prenom NOM] et [Titre] pour l'identité de celui qui rédige le courrier. N'oublie pas que tu es dans un domaine médical, les notes peuvent être des abréviations comme X-X-X indiquant les prises dans la journée (matin, midi, soir). Suis la trame suivante (ce sont les catégories, elles doivent être séparées et il faut que chaque titre apparait sans exception) : Antécédents, Suivi et traitements à l’entrée, Mode de vie, Histoire de la maladie, Synthèse du séjour, Examens paracliniques, Traitements à la sortie, Évènements intercurrents survenus durant le séjour, Accompagnement médico-social, Projet thérapeutique et organisation du suivi, Conclusion. Je veux les titres en gras et les traitements et antécédents sous forme de liste. N'invente rien, base toi uniquement sur les informations qui te sont fournies. Si les informations ne sont pas suffisantes pour renseigner le contenu de chaque titre, laisse des indications entre crochets (exemple : le patient a été pris en charge pour [description du problème])."

      const completion = await openai.chat.completions.create({
          messages: [
              { role: "system", content: (activatedButton === "hospitalisation") ? 
                systemMessageHospitalisation : systemMessageConsultation },
              { role: "user", content: userInput }
          ],
          model: "gpt-3.5-turbo",
      })
      
      setUResponse(completion.choices[0].message.content)
      const formattedResponse = formatText(completion.choices[0].message.content)
      setResponse(formattedResponse)
      setLoading(false)
    } catch (error) {
      console.error('Erreur lors de la requête à l\'API', error)
    }
  }
    
  const formatText = (text) => {
    const titles = ["Antécédents :", "Suivi et traitements à l’entrée :", "Mode de vie :", "Histoire de la maladie :", "Synthèse du séjour :", "Examens paracliniques :", "Traitements à la sortie :", "Évènements intercurrents survenus durant le séjour :", "Accompagnement médico-social :", "Projet thérapeutique et organisation du suivi :", "Conclusion :"]
    titles.forEach((title) => {
      const regex = new RegExp(`(${title})`, 'gi')
      text = text.replace(regex, '<strong>$1</strong>')
    })

    return text
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(unformattedResponse).then(() => {
        console.log("succès")
        setCopied(true)
        setTimeout(() => {
          setCopied(false);
        }, 2000)
      }, (err) => {
        console.log("échec")
      }
    );
  };
  
  function DisplayCourrier() {
    if (response) {
      return (
      <div className='container-response'>
        <button style={{fontSize: 15, padding: '5px 40px', float: 'right'}} onClick={copyToClipboard}><FontAwesomeIcon icon={faCopy} />{isCopied && ' Copié !'}</button>
        <h2>Courrier</h2>
        <p className='response' dangerouslySetInnerHTML={{ __html: response }}></p>
      </div>
      )
    }
  }

  return (
      <div className="container">
        <div className='disclaimer'>Version bêta</div>
        <div className='buttons-inline'>
          <button 
            className={activatedButton === 'consultation' ? 'active' : ''}
            onClick={() => setActivatedButton('consultation')}>{activatedButton === 'consultation' ? '✓' : ''} Courrier de consultation</button>
          <button 
            className={activatedButton === 'hospitalisation' ? 'active' : ''}
            onClick={() => setActivatedButton('hospitalisation')}>{activatedButton === 'hospitalisation' ? '✓' : ''} Courrier d'hospitalisation</button>
        </div>
        <textarea
          value={userInput}
          disabled={recordingRef ? false : true}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder={recordingRef ? 'Placez vos notes et cliquez sur le bouton Rédiger pour générer le courrier':'Génération du contenu en cours...'}
        />
        {loading && (
          <BeatLoader
            color="#0051a9"
            loading={loading}
            size={20}
            aria-label="Chargement en cours"
            data-testid="loader"
          />
        )}
        <div className='buttons-inline'>
          <button onClick={handleSendClick} disabled={loading? true : false}>Rédiger</button>
          <Record recordingRef={recordingRef} setUserInput={setUserInput} />
        </div>
        <DisplayCourrier />
      </div>
    )
  }

export default Courrier
