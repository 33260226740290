import React from 'react';
import Courrier from './Courrier';
import Header from './Header';

function Dashboard() {
    return (
        <div>
            <Header />
            <Courrier />
        </div>
    )
}

export default Dashboard;