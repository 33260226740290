import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from './firebase'

const Header = () => {
    const [userName, setUserName] = useState(null)
    const navigate = useNavigate()
    const { pathname } = useLocation()

    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             if (pathname === "/SignIn") navigate('/')
    //             if (pathname === "/SignUp") navigate('/')
    //             setUserName(user.displayName)
    //         } else {
    //             console.log("Utilisateur déconnecté")
    //             navigate('/SignIn')
    //         }
    //       })
         
    // }, [userName, navigate, pathname])

    // const handleLogout = () => {               
    //     signOut(auth).then(() => {
    //         console.log("Déconnecté avec succès")
    //     }).catch((error) => {
    //         console.log('erreur lors de la déconnexion', error)
    //     })
    // }

  return (
    <div>
        <header style={headerStyle}>
            <div style={titleStyle}>
                <h1 style={{marginTop: userName === null ? 0 : 'revert'}}>Documed</h1>
                { userName && (<h4>Vos courriers, {userName}</h4>) }
            </div>
            {/* <div style={signInSignUpStyle}>
                {userName ? (
                    <button style={linkStyle} onClick={handleLogout}>Se déconnecter</button>
                    ) : (
                        <Link to="/SignIn"><button style={linkStyle}>Connexion / Inscription</button></Link>
                        )}
            </div> */}
        </header>
    </div>
  )
}

const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    padding: '10px',
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    boxShadow: "0px 3px 3px 0px #adacac"
  }
  
const titleStyle = {
    marginLeft: '20px',
}

const signInSignUpStyle = {
    marginRight: '20px',
}

const linkStyle = {
    margin: '0 10px',
    textDecoration: 'none',
}

export default Header
